/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


$.extend( true, $.fn.dataTable.defaults, {
   language: {
    sEmptyTable:     "Nessun dato presente nella tabella",
    sInfo:           "Vista da _START_ a _END_ di _TOTAL_ elementi",
    sInfoEmpty:      "Vista da 0 a 0 di 0 elementi",
    sInfoFiltered:   "(filtrati da _MAX_ elementi totali)",
    sInfoPostFix:    "",
    sInfoThousands:  ".",
    sLengthMenu:     "Visualizza _MENU_ elementi",
    sLoadingRecords: "Caricamento...",
    sProcessing:     "Elaborazione...",
    sSearch:         "Cerca:",
    sZeroRecords:    "La ricerca non ha portato alcun risultato.",
    oPaginate: {
        sFirst:      "Inizio",
        sPrevious:   "Precedente",
        sNext:       "Successivo",
        sLast:       "Fine"
    },
    oAria: {
        sSortAscending:  ": attiva per ordinare la colonna in ordine crescente",
        sSortDescending: ": attiva per ordinare la colonna in ordine decrescente"
    }
   }
} );
