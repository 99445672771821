/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */


const $ = require('jquery');
global.$ = global.jQuery = $;

const Moment = require('moment');
global.moment = Moment;
window.moment = Moment;
import 'moment/locale/it';  // without this line it didn't work
moment.locale('it');
require('tempusdominus-bootstrap-4');
import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.css';
$.fn.datetimepicker.Constructor.Default = $.extend({},
            $.fn.datetimepicker.Constructor.Default,
            { icons:
                    { time: 'fas fa-clock',
                        date: 'fas fa-calendar',
                        up: 'fas fa-arrow-up',
                        down: 'fas fa-arrow-down',
                        previous: 'fas fa-arrow-circle-left',
                        next: 'fas fa-arrow-circle-right',
                        today: 'far fa-calendar-check-o',
                        clear: 'fas fa-trash',
                        close: 'far fa-times' } });
// ********** ADMIN LTE
/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

//CSS ***************************************************************
import 'admin-lte/plugins/fontawesome-free/css/all.min.css';
//Tempusdominus Bbootstrap 4 -->
//import 'admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css';
//iCheck -->
import 'admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
//JQVMap -->
import 'admin-lte/plugins/jqvmap/jqvmap.min.css';
//Theme style -->
import 'admin-lte/dist/css/adminlte.min.css';
//overlayScrollbars -->
import 'admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css';
//Toastr
import 'admin-lte/plugins/toastr/toastr.min.css';
//Daterange picker -->
import 'admin-lte/plugins/daterangepicker/daterangepicker.css';
//summernote -->
import 'admin-lte/plugins/summernote/summernote-bs4.css';
//datatables
import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
import 'admin-lte/plugins/datatables-responsive/css/responsive.bootstrap4.min.css';
import 'admin-lte/plugins/datatables-colreorder/css/colReorder.bootstrap4.min.css';
//Select2
import 'admin-lte/plugins/select2/css/select2.min.css';
import 'admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css';
//
//
//JAVASCRIPT*********************************************************************
//Bootstrap 4 -->
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js';
//ChartJS -->
import 'admin-lte/plugins/chart.js/Chart.min.js';
//Sparkline -->
import 'admin-lte/plugins/sparklines/sparkline.js';
//JQVMap -->
import 'admin-lte/plugins/jqvmap/jquery.vmap.min.js';
import 'admin-lte/plugins/jqvmap/maps/jquery.vmap.usa.js';
//jQuery Knob Chart -->
import 'admin-lte/plugins/jquery-knob/jquery.knob.min.js';
//daterangepicker
//import 'moment/dist/moment.js';
import 'admin-lte/plugins/daterangepicker/daterangepicker.js';
//Tempusdominus Bootstrap 4 -->

//import 'admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js';
//Summernote -->
import 'admin-lte/plugins/summernote/summernote-bs4.min.js';
//overlayScrollbars -->
import 'admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js';
//Toastr
import toastr from 'admin-lte/plugins/toastr/toastr.min.js';
window.toastr = toastr;
toastr.options = {
  "closeButton": true,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "toast-top-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "5000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}


//DataTables -->
import 'admin-lte/plugins/datatables/jquery.dataTables.min.js';
import 'admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4.min.js';
import 'admin-lte/plugins/datatables-responsive/js/dataTables.responsive.min.js';
import 'admin-lte/plugins/datatables-responsive/js/responsive.bootstrap4.min.js';
//PLUGINS LOCALI
//resize colonne
import './datatables-extra-plugins/ColReorderWithResize.js';
//i18n ITALIANO
import './datatables-extra-plugins/italiano.js';


//Select2
import 'admin-lte/plugins/select2/js/select2.full.min.js';
//AdminLTE App -->
import 'admin-lte/dist/js/adminlte.min.js';


// ****************** FINE ADMIN LTE




import './select2entity.js';


//FOS ROUTING
const routes = require('./fos_js_routes.json');
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

Routing.setRoutingData(routes);
console.log(Routing);
window.Routing = Routing;

require ('../css/app.css'); 
const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);


